//DEV
//export const API_URL = process.env.API_URL || "http://localhost:8081/api" ;
export const API_URL = "https://dev.omint-backoffice-cotizador-backend.valtech.com/api";

export const EXPORT_GRID_ENDPOINT = API_URL + "/ExportarListadoAfiliaciones";
export const LOGIN_ENDPOINT = API_URL + "/login/authenticate";
export const AFILIACIONES_ENDPOINT = API_URL + "/ObtenerListadoAfiliaciones";
export const STATES_ENDPOINT = API_URL + "/ObtenerEstadosAceptados/0";
export const PROVINCIAS_ENDPOINT = API_URL + "/provincias";
export const ASESORES_ENDPOINT = API_URL + "/ObtenerAsesores";
export const REPORTS_ENDPOINT = API_URL + "/ListadoAportes";
export const EXPORT_REPORTS_ENDPOINT = API_URL + "/ExportarListadoAportes";
export const ASESOR_ENDPOINT = API_URL + "/Asesor";
export const ASESOR_DELETE_ENDPOINT = API_URL + "/Asesor";
export const ASESOR_PUT_ENDPOINT = API_URL + "/Asesor";
export const PROFILE_ENDPOINT = API_URL + "/afiliacion/detalle";
export const OBSERVATIONS_ENDPOINT = API_URL + "/RegistrarEventoAfiliacion";
export const EVENTS_ENDPOINT = API_URL + "/afiliacion/detalle/eventos";
export const PROFILE_STATES_ENDPOINT = API_URL + "/ObtenerTransicionesAceptadas";
export const CHANGE_PLAN_ENDPOINT = API_URL + "/RegistrarEventoAfiliacion";
export const REGULATION_STATUS_ENDPOINT = API_URL + "/ActualizarEstadoDesregulacion";
export const DOWNLOAD_DNI_ENDPOINT = API_URL + "/afiliacion/dni/";
export const DOWNLOAD_RECEIPT = API_URL + "/afiliacion/Recibo/";
export const EXPORT_APORTES_GRID_ENDPOINT = API_URL + "/ExportarListadoAportes";
export const APORTES_ENDPOINT = API_URL + "/ListadoAportes";

//http://omint-web.cloudapp.net:4000/api/login/authenticate
